import Vue from 'vue';
import { rehydrate } from 'overmind';
import { Notify } from 'quasar';
import { AnswerSurveyStatus, PlanInformationStatus, RescueVoucherStatus, ShowOnBoardingModalStatus, SurveyQuestionStatus, UsersListStatus, } from './state';
import { clearAllCache } from '../localStorage';
import { getCreditsResult } from '@/shared/utils/helpers';
import { mixpanelUpdateUserCreditsConsumption, } from '@/services/mixpanel';
export const getUserCreditsInformation = async ({ effects, }) => {
    const res = await effects.users.usersApi.getUserInformation();
    if (res && res.status == 200 && res.data) {
        return res.data;
    }
};
//GET USER INFORMATION
export const getUserInformation = async ({ state, effects, actions, }) => {
    //@ts-ignore
    actions.users.setPlanInformationStatus(PlanInformationStatus.FETCHING);
    state.users.statusResponse = {};
    const res = await effects.users.usersApi.getUserInformation();
    if (res.status === 200 || res.status === 201) {
        const credits = res.data.credits;
        //@ts-ignore
        actions.users.setPlanInformation(res.account);
        //@ts-ignore
        actions.users.setCreditsAvailable(credits);
        //@ts-ignore
        actions.users.setPlanInformationStatus(PlanInformationStatus.FETCH_SUCCESS);
    }
    else if (res.response.status === 401) {
        //@ts-ignore
        actions.users.setPlanInformationStatus(PlanInformationStatus.FETCH_ERROR);
        state.users.statusResponse = {
            type: 'No authorize',
            //@ts-ignore
            code: res.response.status,
            message: 'No authorize',
        };
    }
    else if (res.response.status === 404) {
        //@ts-ignore
        actions.users.setPlanInformationStatus(PlanInformationStatus.NOT_FOUND);
        state.users.statusResponse = {
            type: 'User information',
            code: 404,
            message: 'No Found',
        };
    }
};
export const setPlanInformationStatus = ({ state: { users: state } }, status) => {
    state.planInformationStatus = status;
};
export const setCreditsAvailable = ({ state }, credits) => {
    state.users.credits = credits;
};
//GET USER ME
export const getUserMe = async ({ state, effects, actions, }) => {
    try {
        const result = await effects.users.usersApi.getUserMe();
        if (result.status &&
            result.status === 200 &&
            result.data &&
            result.data.data) {
            const attributes = result.data.data.attributes;
            const emailUser = attributes.email.split('@');
            state.users.currentUserName = attributes.name;
            state.users.userLoggedRole = attributes.role;
            state.users.currentUserEmail = attributes.email;
            state.users.subDomainEmailUser = emailUser[1];
            state.users.have_password = attributes.have_password;
            const currentUser = {
                id: result.data.id,
                onboardingRememberMe: attributes.onboarding_remember_me,
                name: attributes.name,
                email: attributes.email,
                role: attributes.role,
                featureGroup: attributes.feature_group,
                userInFirstMonth: attributes.user_in_first_month,
                hasOnboarding: attributes.has_onboarding,
                csEmail: attributes.account_cs ? attributes.account_cs.cs_email : '',
                csAvatarUrl: attributes.account_cs
                    ? attributes.account_cs.cs_avatar
                    : '',
                accountType: attributes.account_type,
                origin: attributes.account_origin ? attributes.account_origin : '',
                features: attributes.features || [],
                customer_id: attributes.customer_id,
                superlogica_id: attributes.superlogica_id,
                csgpt: attributes.csgpt,
                canReactivate: attributes.can_reactivate,
                sharableAccessToken: attributes.sharable_access_token,
                showSpeedmailApp: attributes.show_speedmail_app,
            };
            //@ts-ignore
            actions.users.setCurrentUser(currentUser);
        }
    }
    catch (error) {
        console.log(error);
    }
};
export const getUserMeByToken = async ({ state, effects, }) => {
    const result = await effects.users.usersApi.getUserMe();
    if (result.status === 200 && result.data && result.data.data)
        return result;
};
export const setLocalStorageUserPlanInformation = ({ state: { users: state }, }) => {
    localStorage.setItem('user_name_logged', state.currentUserName);
    localStorage.setItem('sub_domain_user_logged', state.subDomainEmailUser);
    if (state.planInformation) {
        localStorage.setItem('user_plan_name', state.planInformation.planName);
        //@ts-ignore
        localStorage.setItem('end_date_plan_user', state.planInformation.createdAt);
    }
};
export const getBasicLoginInformation = async ({ state: { users: state }, effects, }) => {
    const result = await effects.users.usersApi.getBasicLoginInformation();
    if (result && result.status == 200) {
        state.accountInformation = {
            firstDayUse: result.data.first_day_use,
            firstWeekUse: result.data.first_week_use,
            freeAccount: result.data.free_account,
        };
    }
};
export const ActiveConfig = async ({ state, effects, actions }, { value }) => {
    if (value === true) {
        state.users.configActive = true;
    } // } else {
    //   state.users.configActive = false;
    // }
};
export const setAllUsers = ({ state }, UserPayload) => {
    state.users.users = UserPayload;
};
export const filterUserManaged = ({ state }, string) => {
    state.users.users = state.users.users.filter((item) => {
        return item.attributes.name.toLowerCase().match(string.toLowerCase());
    });
};
export const setPlanInformation = ({ state }, planInformation) => {
    state.users.planInformation = planInformation;
};
// //GET USER GERENCIADOR
export const getSubUsers = async ({ state: { users: state }, effects: { users: effects }, actions: { users: actions }, }) => {
    state.statusResponse = {};
    state.users = [];
    //@ts-ignore
    actions.setGetUsersStatus(UsersListStatus.FETCHING);
    const result = await effects.usersApi.getSubUsers();
    if (result) {
        if (result.status === 200 && result.data) {
            if (result.data.data && Array.isArray(result.data.data)) {
                //@ts-ignore
                actions.setSubUsers([...result.data.data]);
                //@ts-ignore
                actions.setGetUsersStatus(UsersListStatus.RECEIVED);
            }
        }
        else if (result.response.status === 401) {
            //@ts-ignore
            actions.setGetUsersStatus(UsersListStatus.ERROR);
            //@ts-ignore
            actions.setUserStatusResponse({
                type: 'No authorize',
                code: result.response.status,
                message: 'No authorize',
            });
            Notify.create({
                message: 'Você não possuí permissão para esta ação!',
                type: 'error',
            });
        }
        else if (result.response.status === 404) {
            //@ts-ignore
            actions.setGetUsersStatus(UsersListStatus.NOT_FOUND);
            //@ts-ignore
            actions.setUserStatusResponse({
                type: 'No found',
                code: 404,
                message: 'No found',
            });
            state.users = [];
        }
    }
};
export const setGetUsersStatus = ({ state: { users: state } }, value) => {
    state.listUsersStatus = value;
};
// POST USER GERENCIADOR
export const createUsers = async ({ state: { users: state }, effects: { users: effects }, actions: { users: actions }, }, subUser) => {
    state.statusResponse = {};
    const result = await effects.usersApi.createUser(subUser);
    if (result.status === 201) {
        //@ts-ignore
        actions.setUserStatusResponse({
            type: 'create-user',
            code: result.status,
            message: 'Susscesfully',
        });
        Notify.create({
            message: 'Usuário criado com sucesso',
            type: 'success-user',
        });
    }
    else if (result.response.status === 401) {
        //@ts-ignore
        actions.setUserStatusResponse({
            type: 'No authorize',
            code: result.response.status,
            message: 'No authorize',
        });
        Notify.create({
            message: 'Você não possuí permissão para esta ação!',
            type: 'error',
        });
    }
    else if (result.response.status === 422) {
        //@ts-ignore
        actions.setUserStatusResponse({
            type: 'create-user',
            code: result.response.status,
            message: 'The email is aready exist',
        });
    }
    return result;
};
export const answerSurvey = async ({ state: { users: state }, effects: { users: effects } }, survey) => {
    state.answerSurveyStatus = AnswerSurveyStatus.IDLE;
    const result = await effects.usersApi.answerSurvey(survey);
    if (result && result.status === 201) {
        state.answerSurveyStatus = AnswerSurveyStatus.CREATED;
        state.surveyInformation = result.data.data.attributes;
        Notify.create({
            message: 'Avaliação salva com sucesso!',
            type: 'success',
        });
    }
    else {
        state.answerSurveyStatus = AnswerSurveyStatus.ERROR;
        Notify.create({
            type: 'error',
            message: 'Aconteceu algo inesperado e não foi possível salvar sua avaliação, por favor tente mais tarde',
        });
    }
};
export const getAllSurveysQuestions = async ({ state: { users: state }, effects: { users: effects }, }) => {
    const result = await effects.usersApi.getAllSurveyQuestions();
    if (result && result.status === 200 && result.data) {
        if (result.data.length > 0) {
            state.surveyQuestionStatus = SurveyQuestionStatus.RECEIVED;
            state.surveyQuestion = result.data;
        }
    }
    else {
        state.surveyQuestionStatus = SurveyQuestionStatus.ERROR;
    }
};
export const setSurveyQuestionStatus = ({ state: { users: state } }, status) => {
    state.surveyQuestionStatus = status;
};
export const createAttemptsLog = async ({ effects: { users: effects }, state: { users: state } }, type) => {
    const result = await effects.usersApi.createAttemptsLog({ type });
    if (result) {
        state.createAttemptsLogStatus = {
            actionType: type,
            success: true,
        }; //@ts-ignore
    }
    else {
        //@ts-ignore
        state.createAttemptsLogStatus = {
            actionType: type,
            success: false,
        };
    }
};
export const getShowSurveyModal = async ({ effects: { users: effects }, state: { users: state }, }) => {
    const result = await effects.usersApi.showSurveyModal();
    if (result && result.status === 200) {
        state.showSurveyModal = result.data.check_permission_to_show_survey;
    }
};
// PATCH USER GERENCIADOR
export const updateUser = async ({ state: { users: state }, effects: { users: effects }, actions: { users: actions }, }, payload) => {
    state.statusResponse = {};
    const result = await effects.usersApi.updateUser(payload.id, payload);
    if (result && result.status === 200) {
        //@ts-ignore
        actions.setUserStatusResponse({
            type: 'edit-user',
            code: result.status,
            message: 'Susscesfully',
        });
        Notify.create({
            message: 'Usuário editado com sucesso',
            type: 'success-user',
        });
    }
    else if (result && result.response.status === 401) {
        //@ts-ignore
        actions.setUserStatusResponse({
            type: 'No authorize',
            code: result.response.status,
            message: 'No authorize',
        });
        Notify.create({
            message: 'Você não possuí permissão para esta ação!',
            type: 'error',
        });
    }
    else if (result && result.response.status === 422) {
        //@ts-ignore
        actions.setUserStatusResponse({
            type: 'edit-user',
            code: result.response.status,
            message: 'The email is aready exist',
        });
    }
    return result;
};
// //DELETED USER GERENCIADOR
export const removeUser = async ({ effects: { users: effects }, actions: { users: actions } }, { userId }) => {
    const result = await effects.usersApi.removeUser(userId);
    if (result && result.status === 200) {
        //@ts-ignore
        actions.setUserStatusResponse({
            type: 'delete-user',
            code: result.status,
            message: 'Succesfully',
        });
        Notify.create({
            message: 'Usuário deletado com sucesso',
            type: 'success-deleted',
        });
    }
    else if (result && result.response.status === 401) {
        //@ts-ignore
        actions.setUserStatusResponse({
            type: 'No authorize',
            code: result.response.status,
            message: 'No authorize',
        });
        Notify.create({
            message: 'Você não possuí permissão para esta ação!',
            type: 'error',
        });
    }
    return result;
};
export const setCurrentUser = ({ state: { users: state } }, user) => {
    state.currentUser = user;
};
export const setSubUsers = ({ state: { users: state } }, subUsers) => {
    state.users = subUsers;
};
export const removeSubUsers = ({ state: { users: state } }, index) => {
    state.users.splice(index, 1);
};
export const setUserStatusResponse = ({ state: { users: state } }, { code, message, type }) => {
    state.statusResponse = { type, code, message };
};
//RESET PASSWORD
export const resetPassword = async ({ state, effects, actions }, { email }) => {
    state.users.statusResponse = {};
    const payload = { email: email };
    await effects.users.usersApi
        .resetPassword(payload)
        .then(res => {
        if (res.status === 201) {
            const statusResponse = {
                type: 'reset-password',
                code: res.status,
                message: 'Success',
            };
            state.users.statusResponse = statusResponse;
        }
        else {
            const statusResponse = {
                type: 'reset-password',
                code: 404,
                message: 'Email no found',
            };
            state.users.statusResponse = statusResponse;
        }
    })
        .catch(err => {
        const statusResponse = {
            type: 'reset-password',
            code: 404,
            message: 'Email no found',
        };
        state.users.statusResponse = statusResponse;
    });
};
//PATCH USER INFORMATION
export const changePassword = async ({ state, effects, actions }, { old_password, password, password_confirmation }) => {
    state.users.statusResponse = {};
    const payload = {
        user: {
            old_password: old_password,
            password: password,
            password_confirmation: password_confirmation,
        },
    };
    await effects.users.usersApi
        .changePassword(payload)
        .then(res => {
        if (res.status === 201) {
            const statusResponse = {
                type: 'change-password',
                code: res.status,
                message: 'Susscesfully',
            };
            state.users.statusResponse = statusResponse;
        }
        else if (res.response.status === 422 &&
            res.response.data.errors[0] === 'Password wrong') {
            const statusResponse = {
                type: 'change-password',
                code: res.response.status,
                message: res.response.data.errors[0],
            };
            state.users.statusResponse = statusResponse;
        }
        else if (res.response.status === 401) {
            const statusResponse = {
                type: 'No authorize',
                code: res.response.status,
                message: 'No authorize',
            };
            state.users.statusResponse = statusResponse;
        }
    })
        .catch(err => { });
};
//GET TOKEN PIPEDRIVE
export const getTokenPipeDrive = async ({ effects, actions }, { token }) => {
    await effects.users.usersApi
        .getApiTokenPipedrive(token)
        .then(res => {
        if (res.status === 200 || res.status === 201) {
            //@ts-ignore
            actions.users.setTokenPipeDrive(token);
            Notify.create({
                message: 'Seu token do Pipedrive foi salvo com sucesso. Agora você pode exportar para o Pipedrive!',
                type: 'success',
            });
        }
        else if (res.status === undefined) {
            localStorage.removeItem('tokenPipeDrive');
            Notify.create({
                message: 'Erro! Verifique se o campo do Pipedrive está vazio ou se o Token está inválido e tente novamente.',
                type: 'error',
            });
        }
    })
        .catch(err => err);
};
export const getTokenMeetime = async ({ effects, actions }, { token }) => {
    await effects.users.usersApi
        .getApiTokenMeetime(token)
        .then(res => {
        if ([200, 201].includes(res.status)) {
            //@ts-ignore
            actions.users.setTokenMeetime(token);
            Notify.create({
                message: 'Seu token do Meetime foi salvo com sucesso. Agora você pode exportar para o Meetime!',
                type: 'success',
            });
        }
        else if (res.status === undefined) {
            localStorage.removeItem('tokenMeetime');
            Notify.create({
                message: 'Erro! Verifique se o campo do token está vazio ou se está válido e tente novamente.',
                type: 'error',
            });
        }
    })
        .catch(err => err);
};
export const isSankhyaUserAccount = ({ state }) => {
    const email = state.users.currentUser ? state.users.currentUser.email : '';
    return !!email.match('@sankhya.com.br') || !!email.match('@speedio');
};
export const getTokenFunilDeVendas = async ({ effects, actions }, { tokenFunilDeVendas, codSeller, codSalesChannel, keyCodFunilDeVendas }) => {
    const allFieldsFilled = [
        tokenFunilDeVendas,
        codSeller,
        codSalesChannel,
        keyCodFunilDeVendas,
    ].every(e => !!e);
    if (allFieldsFilled) {
        const result = await effects.users.usersApi.getApiTokenFunilDeVendas(tokenFunilDeVendas);
        if (result && result.status === 200 && result.data) {
            //@ts-ignore
            actions.users.setTokenAndCodsFunilDeVendas({
                tokenFunilDeVendas,
                codSeller,
                codSalesChannel,
                keyCodFunilDeVendas,
            });
            Notify.create({
                message: 'A chave de integração e os códigos, foram salvos com sucesso. Agora você pode exportar para o Funil de Vendas!',
                type: 'success',
            });
        }
        else {
            localStorage.removeItem('tokenFunilDeVendas');
            Notify.create({
                message: 'Erro! Verifique se os campos estão válidos e tente novamente.',
                type: 'error',
            });
        }
    }
    else {
        Notify.create({
            message: 'Todos os campos devem ser informados!',
            type: 'error',
        });
        return;
    }
};
//GET TOKEN PIPERUN
export const getTokenPiperun = async ({ effects, actions }, { token }) => {
    if (token !== '') {
        await effects.users.usersApi
            .getApiTokenPiperun(token)
            .then(res => {
            if (res.status === 200 || res.status === 201) {
                //@ts-ignore
                actions.users.setTokenPiperun(token);
                Notify.create({
                    message: 'Seu token do Piperun foi salvo com sucesso. Agora você pode exportar para o Piperun!',
                    type: 'success',
                });
            }
            else {
                localStorage.removeItem('tokenPiperun');
                Notify.create({
                    message: 'Erro! Verifique se o campo do Piperun está vazio ou se o Token está inválido e tente novamente.',
                    type: 'error',
                });
            }
        })
            .catch(err => err);
    }
    else {
        localStorage.removeItem('tokenPiperun');
        Notify.create({
            message: 'Erro! Verifique se o campo do Piperun está vazio ou se o Token está inválido e tente novamente.',
            type: 'error',
        });
    }
};
//GET TOKEN AGENDOR
export const getTokenAgendor = async ({ effects, actions }, { token }) => {
    await effects.users.usersApi
        .getApiTokenAgendor(token)
        .then(res => {
        if (res.status === 200 || res.status === 201) {
            //@ts-ignore
            actions.users.setTokenAgendor(token);
            Notify.create({
                message: 'Seu token do Agendor foi salvo com sucesso. Agora você pode exportar para o Agendor!',
                type: 'success',
            });
        }
        else {
            localStorage.removeItem('tokenAgendor');
            Notify.create({
                message: 'Erro! Verifique se o campo do Agendor está vazio ou se o Token está inválido e tente novamente.',
                type: 'error',
            });
        }
    })
        .catch(err => err);
};
//SET UPDATED PERCENT PIPEDRIVE
export const setPipedriveUploadPercent = async ({ state: { users: state } }, { companies, company }) => {
    const totalPercentValue = company / companies;
    state.countCompaniesSendedToPipedrive = totalPercentValue;
};
//UPDATED LABEL PERCENT PIPEDRIVE
export const setPipedriveLabelPercent = async ({ state: { users: state } }, { companies, company }) => {
    let percentLabel = ((company / companies) * 100).toFixed(2);
    percentLabel = `${percentLabel} %`;
    state.totalPercentLabelPipedrive = percentLabel;
};
export const setPipedriveInfo = ({ state: { users: state } }, { info }) => {
    state.pipedrive = info;
};
//GET TOKEN PLOOMES
export const getTokenPloomes = async ({ effects, actions }, { token }) => {
    await effects.users.usersApi
        .getApiTokenPloomes(token)
        .then(res => {
        if (res.status === 200 || res.status === 201) {
            //@ts-ignore
            actions.users.setTokenPloomes(token);
            Notify.create({
                message: 'Seu token do Ploomes foi salvo com sucesso. Agora você pode exportar para o Ploomes!',
                type: 'success',
            });
        }
        else if (res.status === undefined) {
            localStorage.removeItem('tokenPloomes');
            Notify.create({
                message: 'Erro! Verifique se o Token está inválido e sua conta e usuário do Ploomes possui permissões para integração e tente novamente!',
                type: 'error',
            });
        }
    })
        .catch(err => {
        Notify.create({
            message: 'O token fornecido é inválido! Verifique seu token e tente novamente',
            type: 'error',
        });
    });
};
//GET ACCESS TOKEN TOTVS
export const getTokensToTvs = async ({ effects, actions }, { accessToken = '', integrationToken = '' }) => {
    await effects.users.usersApi
        .getApiTokenToTvs({ accessToken, integrationToken })
        .then(res => {
        if (res && [200, 201].includes(res.status)) {
            //@ts-ignore
            actions.users.setTokensToTvs({ accessToken, integrationToken }); //@ts-ignore
            actions.users.setStatusTokensToTvs(true);
            Notify.create({
                message: 'Seu token TOTVS CRM e do Integrador foram salvos com sucesso!',
                type: 'success',
            });
            return;
        }
        else if (res.status === 401) {
            //@ts-ignore
            localStorage.removeItem('accessTokenToTvs'); //@ts-ignore
            actions.users.setStatusTokensToTvs(false);
            return;
        }
        else if (res.status === 403) {
            localStorage.removeItem('integrationTokenToTvs'); //@ts-ignore
            actions.users.setStatusTokensToTvs(false);
        }
    })
        .catch(err => {
        Notify.create({
            message: 'O token TOTVS CRM fornecido é inválido! Verifique seu token e tente novamente',
            type: 'error',
        });
    });
};
export const setStatusTokensToTvs = ({ state: { users: state } }, value) => {
    state.statusTokensToTvs = value;
};
export const exportToTvs = async ({ effects, state }, { infosetId, payload }) => {
    const accessTokenToTvs = localStorage.getItem('accessTokenToTvs') || state.users.accessTokenToTvs;
    const integrationTokenToTvs = localStorage.getItem('integrationTokenToTvs') ||
        state.users.integrationTokenToTvs;
    if (!!accessTokenToTvs && !!integrationTokenToTvs) {
        return await effects.users.usersApi.exportToToTvs({
            infosetId,
            accessTokenToTvs,
            integrationTokenToTvs,
            payload,
        });
    }
    else {
        Notify.create({
            message: 'Erro! Verifique se os campos da ToTvs estão vazios ou se os Tokens estão válidos e tente novamente.',
            type: 'error',
        });
    }
};
//SET TOKEN TOTVS IN STATE
export const setTokensToTvs = ({ state: { users: state } }, { accessToken, integrationToken }) => {
    localStorage.setItem('accessTokenToTvs', accessToken);
    localStorage.setItem('integrationTokenToTvs', integrationToken);
    state.accessTokenToTvs = accessToken;
    state.integrationTokenToTvs = integrationToken;
};
export const exportPipedrive = async ({ effects }, { infosetId, payload }) => {
    const token = localStorage.getItem('tokenPipeDrive') || '';
    return await effects.users.usersApi.exportToPipedrive({
        infosetId,
        token,
        payload,
    });
};
export const exportAgendor = async ({ state: { users: state }, effects }, { infosetId, payload }) => {
    const token = localStorage.getItem('tokenAgendor')
        ? localStorage.getItem('tokenAgendor')
        : state.tokenAgendor;
    if (token) {
        return await effects.users.usersApi.exportToAgendor({
            infosetId,
            token,
            payload,
        });
    }
    else {
        Notify.create({
            message: 'Erro! Verifique se o campo do Agendor está vazio ou se o Token está inválido e tente novamente.',
            type: 'error',
        });
    }
};
export const exportPiperun = async ({ state: { users: state }, effects }, { infosetId, payload, onlyValidPhones }) => {
    const token = localStorage.getItem('tokenPiperun')
        ? localStorage.getItem('tokenPiperun')
        : state.tokenPiperun;
    if (token) {
        return await effects.users.usersApi.exportToPiperun({
            infosetId,
            token,
            payload,
            onlyValidPhones,
        });
    }
    else {
        return {
            message: 'Erro! Verifique se o campo do Piperun está vazio ou se o Token está inválido e tente novamente.',
            type: 'error_token',
        };
    }
};
export const exportFunilDeVendas = async ({ state: { users: state }, effects }, { infosetId, payload }) => {
    const token = localStorage.getItem('tokenFunilDeVendas')
        ? localStorage.getItem('tokenFunilDeVendas')
        : state.tokenFunilDeVendas;
    const codsFunilDeVendas = {
        codSeller: localStorage.getItem('codSellerFunilDeVendas') ||
            state.codSellerFunilDeVendas,
        codSalesChannel: localStorage.getItem('codSalesChannelFunilDeVendas') ||
            state.codSalesChannelFunilDeVendas,
        keyCodFunilDeVendas: localStorage.getItem('keyCodFunilDeVendas') || state.keyCodFunilDeVendas,
    };
    if (token) {
        return await effects.users.usersApi.exportToFunilDeVendas({
            infosetId,
            token,
            payload,
            codsFunilDeVendas,
        });
    }
    else {
        return {
            message: 'Erro! Verifique se o campo do Funil de Vendas está vazio ou se o Token está válido e tente novamente.',
            type: 'error_token',
        };
    }
};
export const exportSalesForce = async ({ effects }, { infosetId, payload }) => {
    const token = localStorage.getItem('tokenSalesForce') || '';
    return await effects.users.usersApi.exportToSalesForce({
        infosetId,
        token,
        payload,
    });
};
export const exportPloomes = async ({ effects }, { infosetId, payload }) => {
    const token = localStorage.getItem('tokenPloomes') || '';
    return await effects.users.usersApi.exportToPloomes({
        infosetId,
        token,
        payload,
    });
};
export const exportRDStation = async ({ state: { users: state }, effects }, { infosetId, payload }) => {
    //if (data && data.length > 0) {
    const token = localStorage.getItem('tokenRdSTation') || '';
    return await effects.users.usersApi.exportToRDStation({
        token,
        infosetId,
        payload,
    });
};
export const exportHubspot = async ({ state: { users: state }, effects }, { infosetId, payload }) => {
    const token = localStorage.getItem('tokenHubSpot') || '';
    return await effects.users.usersApi.exportToHubspot({
        infosetId,
        token,
        payload,
    });
};
export const exportZoho = async ({ state: { users: state }, effects }, { infosetId, payload }) => {
    const token = localStorage.getItem('tokenZoho') || '';
    return await effects.users.usersApi.exportToZoho({
        infosetId,
        token,
        payload,
    });
};
export const exportMeetime = async ({ effects }, { infosetId, payload, cadenceApiUrl, ownerSelected, onlyValidPhones }) => {
    const token = localStorage.getItem('tokenMeetime') || '';
    return await effects.users.usersApi.exportToMeetime({
        infosetId,
        token,
        payload,
        cadenceApiUrl,
        ownerSelected,
        onlyValidPhones,
    });
};
export const exportBitrix24 = async ({ effects }, { infosetId, payload }) => {
    return await effects.users.usersApi.exportToBitrix24({
        infosetId,
        payload,
    });
};
//SET TOKEN PIPEDRIVE IN STATE
export const setTokenPipeDrive = ({ state: { users: state } }, token) => {
    localStorage.setItem('tokenPipeDrive', token);
    state.tokenPipeDrive = token;
};
export const setTokenMeetime = ({ state: { users: state } }, token) => {
    localStorage.setItem('tokenMeetime', token);
    state.tokenMeetime = token;
};
export const setTokenAndCodsFunilDeVendas = ({ state: { users: state } }, { tokenFunilDeVendas, codSeller, codSalesChannel, keyCodFunilDeVendas }) => {
    localStorage.setItem('tokenFunilDeVendas', tokenFunilDeVendas);
    state.tokenFunilDeVendas = tokenFunilDeVendas;
    localStorage.setItem('codSellerFunilDeVendas', codSeller);
    state.codSellerFunilDeVendas = codSeller;
    localStorage.setItem('codSalesChannelFunilDeVendas', codSalesChannel);
    state.codSalesChannelFunilDeVendas = codSalesChannel;
    localStorage.setItem('keyCodFunilDeVendas', keyCodFunilDeVendas);
};
//SET TOKEN PIPERUN IN STATE
export const setTokenPiperun = ({ state: { users: state } }, token) => {
    localStorage.setItem('tokenPiperun', token);
    state.tokenPiperun = token;
};
//SET TOKEN AGENDOR IN STATE
export const setTokenAgendor = ({ state: { users: state } }, token) => {
    localStorage.setItem('tokenAgendor', token);
    state.tokenAgendor = token;
};
//SET TOKEN PLOOMES IN STATE
export const setTokenPloomes = ({ state: { users: state } }, token) => {
    localStorage.setItem('tokenPloomes', token);
    state.tokenPloomes = token;
};
//SET TOKEN PLOOMES IN STATE
export const setKeysFieldPloomes = ({ state: { users: state } }, payload) => {
    const field = state.keysContactsPloomes.filter(field => field.Name === payload.Name);
    if (field.length === 0) {
        state.keysContactsPloomes = [...state.keysContactsPloomes, payload];
    }
};
//SET KEY OBJECTS IN STATE
export const setKeysObjectsContactsPloomes = ({ state: { users: state } }, { key, name }) => {
    state.objectKeyContactPloomes[`${key}`] = name;
};
export const recoverPassword = async ({ state, effects, actions }, { token, password, password_confirmation }) => {
    state.users.statusResponse = {};
    const payload = {
        token: token,
        password: password,
        password_confirmation: password_confirmation,
    };
    await effects.users.usersApi
        .recoverPassword(payload)
        .then(res => {
        if (res.status === 201) {
            state.users.statusResponse = {
                type: 'recover-password',
                code: res.status,
                message: 'Susscesfully',
            };
        }
        else {
            state.users.statusResponse = {
                type: 'recover-password',
                code: res.response.status,
                message: 'Error',
            };
        }
    })
        .catch(err => {
        if (err) {
            state.users.statusResponse = {
                type: 'recover-password',
                code: 404,
                message: 'Error',
            };
        }
    });
    // if (result.status === 400 || result.status === 500) {
    //   const statusResponse = {
    //     type: 'change-password',
    //     code: result.status,
    //     message: 'Wrong'
    //   };
    //   state.users.statusResponse = statusResponse;
    // }
    /*eslint-disable */
};
export const setHubSpotToken = ({ state: { users: state }, actions: { users: actions } }, { token }) => {
    localStorage.setItem('tokenHubSpot', token);
    const data = { crm: 'hubspot', token: token };
    //@ts-ignore
    actions.saveCrmAutoplay(data);
    //@ts-ignore
    actions.getCrmAutoplay('hubspot');
    state.tokenHubSpot = token;
};
export const setZohoToken = ({ state: { users: state } }, { token }) => {
    localStorage.setItem('tokenZoho', token);
    state.tokenZoho = token;
};
export const getTokenHubSpot = async ({ effects, actions: { users: actions } }, { client_id, client_secret, code, redirect_uri }) => {
    let result = await effects.users.usersApi.getApiTokenHubSpot({
        grant_type: 'authorization_code',
        client_id,
        client_secret,
        code,
        redirect_uri,
    });
    if (result.status == 201) {
        //@ts-ignore
        actions.setHubSpotToken({ token: result.data.access_token });
    }
};
export const getTokenZoho = async ({ effects, actions: { users: actions } }, { client_id, client_secret, code, redirect_uri }) => {
    const hostname = window.location.hostname;
    let result = await effects.users.usersApi.getApiTokenZoho({
        grant_type: 'authorization_code',
        client_id,
        client_secret,
        code,
        redirect_uri,
    });
    if (result.status == 201) {
        //@ts-ignore
        actions.setZohoToken({ token: result.data.access_token });
    }
};
export const validUrlBitrix24 = async ({ effects, actions: { users: actions } }, { url }) => {
    await effects.users.usersApi
        .validUrlBitrix24({ url })
        .then(res => {
        if (res.status === 200 || res.status === 201) {
            //@ts-ignore
            actions.setBitrix24Url({ url });
            Notify.create({
                message: 'Sua url do Bitrix24 foi salvo com sucesso. Agora você pode exportar para o Bitrix24!',
                type: 'success',
            });
        }
        else if (res.status >= 400) {
            localStorage.removeItem('urlBitrix24');
            Notify.create({
                message: 'Erro! Verifique se o campo do Bitrix24 está vazio ou se sua url está válida e tente novamente.',
                type: 'error',
            });
        }
    })
        .catch(err => err);
};
export const setBitrix24Url = ({ state: { users: state } }, { url }) => {
    localStorage.setItem('urlBitrix24', url);
    state.urlBitrix24 = url;
};
export const getTokenSalesForce = async ({ effects, actions: { users: actions } }, { client_id, client_secret, code }) => {
    const hostname = window.location.hostname;
    let result = await effects.users.usersApi.getApiTokenSalesForce({
        client_id,
        client_secret,
        code,
        redirect_uri: hostname.match('dev')
            ? 'https://dev.speedio.com.br/crm/salesforce/callback'
            : hostname.match('test')
                ? 'https://test.speedio.com.br/crm/salesforce/callback'
                : hostname.match('app')
                    ? 'https://app.speedio.com.br/crm/salesforce/callback'
                    : 'http://localhost:8080/crm/salesforce/callback',
    });
    if (result && result.status == 201) {
        //@ts-ignore
        actions.setSalesForceToken({ token: result.data.access_token });
    }
};
export const setSalesForceToken = ({ state: { users: state } }, { token }) => {
    localStorage.setItem('tokenSalesForce', token);
    state.tokenSalesForce = token;
};
//GET RDSTATION TOKEN
export const getTokenRDStation = async ({ effects, actions: { users: actions } }, { client_id, client_secret, code }) => {
    let result = await effects.users.usersApi.setApiTokenRdSTation({
        client_id,
        client_secret,
        code,
    });
    if ((result && result.status == 201) || (result && result.status == 200)) {
        //@ts-ignore
        actions.setRDStationToken({ token: result.data.access_token });
    }
};
//SET TOKEN RDSTATION
export const setRDStationToken = async ({ state: { users: state }, effects: { users: effects } }, { token }) => {
    const result = await effects.usersApi.validRDStationToken(token);
    if (result) {
        if (result.status === 200 || result.status === 201) {
            // TODO: remove this localstorage line to trust on /me endpoint
            // to get token rdstation when necessary
            localStorage.setItem('tokenRdSTation', token);
            state.tokenRdSTation = token;
            return true;
        }
        if (result.response.status === 401 || result.response.status === 403) {
            return false;
        }
    }
    return false;
};
export const getNotifications = async ({ state: { users: state }, effects: { users: effects }, actions: { users: actions }, }, { page }) => {
    // @ts-ignore
    const userId = state.currentUser ? state.currentUser.id : Vue.auth.user().id;
    const result = await effects.usersApi.getNotifications(userId, page);
    if (result) {
        if (result.data && Array.isArray(result.data)) {
            const resultSerializer = result.data.map(data => ({
                ...data,
                _id: data._id.$oid,
                user_id: data.user_id.$oid,
                account_id: data.account_id.$oid,
            })); //@ts-ignore
            actions.updateIgnoredNotifications(); //@ts-ignore
            actions.setNotifications(resultSerializer); //@ts-ignore
            actions.verifyNewNotifications();
        }
    }
};
//UPDATE NOTIFICATIONS LOCALLY
export const updateLocalNotifications = ({ state: { users: state }, actions: { users: actions }, }) => {
    const notifications = JSON.parse(localStorage.newNotifications || '[]');
    localStorage.setItem('newNotifications', JSON.stringify([
        ...notifications,
        ...state.notifications
            .map(res => res._id)
            .filter(n => !notifications.includes(n)),
    ]));
};
//ADD IGNORED NOTIFICATIONS LOCALLY
export const addLocalIgnoredNotifications = ({ state, actions: { users: actions } }, notification) => {
    const ignoredNotifications = JSON.parse(localStorage.ignoredNotifications || '[]');
    ignoredNotifications.push(notification._id);
    localStorage.setItem('ignoredNotifications', JSON.stringify(ignoredNotifications)); //@ts-ignore
    actions.addIgnoredNotification(notification);
};
export const addIgnoredNotification = ({ state: { users: state } }, notification) => {
    state.ignoredNotifications.push(notification._id);
};
//UPDATE IGNORED NOTIFICATIONS LOCALLY
export const updateIgnoredNotifications = ({ state: { users: state }, }) => {
    const ignoredNotifications = JSON.parse(localStorage.ignoredNotifications || '[]');
    const notifications = ignoredNotifications.filter(n => !state.ignoredNotifications.includes(n));
    state.ignoredNotifications = [...state.ignoredNotifications, ...notifications];
};
export const verifyNewNotifications = ({ state: { users: state }, actions: { users: actions }, }) => {
    const { notifications } = state;
    const localNotifications = JSON.parse(localStorage.newNotifications || '[]');
    /* const verify = notifications.every(notification =>
      localNotifications.includes(notification._id)
    ); */
    const haveNewNotifications = notifications.length > 0
        ? !localNotifications.includes(notifications[0]._id)
        : false;
    state.haveNewNotifications = haveNewNotifications;
};
export const setNotifications = ({ state: { users: state }, actions: { users: actions } }, notifications) => {
    //@ts-ignore
    const newNotifications = actions.unrepeatedNotifications(notifications);
    state.notifications =
        //@ts-ignore
        actions.getSortNotificationsByCreatedAt(newNotifications);
};
export const getSortNotificationsByCreatedAt = ({}, notifications) => {
    return notifications.sort((a, b) => a.created_at < b.created_at ? 1 : a.created_at > b.created_at ? -1 : 0);
};
export const unrepeatedNotifications = ({ state: { users: state } }, notifications) => {
    const notificationsIds = state.notifications.map(n => n._id);
    const newNotifications = notifications.filter(notification => !notificationsIds.includes(notification._id));
    return [...state.notifications, ...newNotifications];
};
export const addNotification = ({ state }, notification) => {
    if (state.users.notifications) {
        state.users.notifications.push(notification);
    }
    else {
        state.users.notifications = [notification];
    }
};
export const editNotification = ({ state: { users: state } }, notification) => {
    const indexNotification = state.notifications.findIndex(n => n._id === notification._id);
    if (indexNotification > -1) {
        state.notifications[indexNotification] = notification;
    }
};
export const markNotificationsAsRead = async ({ state: { users: state }, effects: { users: effects }, actions: { users: actions }, }, notification) => {
    try {
        const userId = state.currentUser
            ? state.currentUser.id
            : // @ts-ignore
                // @ts-ignore
                Vue.auth.user().id;
        await effects.usersApi.markNotificationsAsRead(userId, notification._id); //@ts-ignore
        actions.editNotification({ ...notification, read: true });
    }
    catch (error) {
        console.error(error);
    }
};
//Action to clean accessToken in state
export const logoutCleanToken = async ({ state }) => {
    state.users.accessToken = null;
};
export const cleanStoreUsers = ({ state }) => {
    rehydrate(state, {
        users: {
            users: [],
            planInformation: {},
            password: '',
            statusOk: false,
            statusError: false,
            currentUserCreated: {},
            accessToken: null,
            currentUserName: '',
            statusResponse: {},
            userLoggedRole: '',
            subDomainEmailUser: '',
            currentUser: null,
        },
    });
};
export const clearCache = async () => {
    clearAllCache();
};
export const setHasUpdate = ({ state: { users: state } }, value) => {
    state.hasUpdate = value;
};
export const showOnBoardingModal = async ({ state: { users: state }, effects: { users: effects }, actions: { users: actions }, }) => {
    const result = await effects.usersApi.showOnBoardingModal();
    //@ts-ignore
    actions.setShowOnBoardingModalStatus(ShowOnBoardingModalStatus.FETCHING);
    if (state.currentUser && !state.currentUser.onboardingRememberMe)
        localStorage.removeItem('onboarding_remeber_me_later');
    if (result && result.status === 200) {
        //@ts-ignore
        actions.setShowOnBoardingModalStatus(ShowOnBoardingModalStatus.RECEIVED);
        state.showOnBoardingModal = result.data.check_permission_to_show_onboarding;
    }
    else if (result && result.status === 404) {
        //@ts-ignore
        actions.setShowOnBoardingModalStatus(ShowOnBoardingModalStatus.NOT_FOUND);
    }
    else {
        //@ts-ignore
        actions.setShowOnBoardingModalStatus(ShowOnBoardingModalStatus.ERROR);
    }
};
export const showOnboardingNotification = async ({ state: { users: state }, effects: { users: effects }, }) => {
    const result = await effects.usersApi.showOnboardingNotification();
    if (result && result.status === 200) {
        state.showOnBoardingNotify = result.data.show_notification;
    }
    else {
        state.showOnBoardingNotify = false;
    }
};
export const showVoucher = async ({ state: { users: state }, effects: { users: effects }, }) => {
    const result = await effects.usersApi.handleVoucherVisibility();
    if (result && result.status === 200) {
        state.showVoucher = result.data.check_permission_to_show_voucher;
    }
    else {
        state.showVoucher = false;
    }
};
export const rescueVoucher = async ({ effects: { users: effects }, state: { users: state }, actions: { users: actions }, }, { allowedVoucher, voucherAction, surveyId }) => {
    const result = await effects.usersApi.rescueVoucher({
        allowedVoucher,
        voucherAction,
        surveyId,
    });
    if (result && result.status === 201 && result.data) {
        //@ts-ignore
        actions.setRescueVoucherStatus(RescueVoucherStatus.FINISHED);
    }
    else {
        //@ts-ignore
        actions.setRescueVoucherStatus(RescueVoucherStatus.ERROR);
    }
};
export const setRescueVoucherStatus = ({ state: { users: state } }, value) => {
    state.rescueVoucherStatus = value;
};
export const setShowOnBoardingModalStatus = ({ state: { users: state } }, value) => {
    state.showOnBoardingModalStatus = value;
};
export const setShowOnBoardingNotify = ({ state: { users: state } }, value) => {
    state.showOnBoardingNotify = value;
};
export const onboardingRegister = async ({ effects: { users: effects }, state: { users: state } }, { comment, selectedOption, emailUser, responsibleSelectedCsMember, creationOfOnboardingByAdmin, }) => {
    const result = await effects.usersApi.onboardingRegister({
        comment,
        selectedOption,
        emailUser,
        responsibleSelectedCsMember,
        creationOfOnboardingByAdmin,
    });
    if (result && result.status === 201) {
        Notify.create({
            message: 'Onboarding resgistrado com sucesso!',
            type: 'success',
        });
        state.statusOnBoarding = result.data.status_onboarding;
        localStorage.removeItem('onboarding_remember_me_later');
    }
};
export const rememberMeLater = async ({ effects: { users: effects }, state: { users: state } }, onboarding_update) => {
    const result = await effects.usersApi.rememberMeLater({
        user_id: state.currentUser.id,
        onboarding_update,
    });
    if (result && result.status === 201) {
        localStorage.setItem('onboarding_remember_me_later', new Date().toISOString());
        if (result.data && result.data.data && result.data.data.attributes) {
            state.currentUser.onboardingRememberMe =
                result.data.data.attributes.onboarding_remember_me;
        }
    }
};
export const recoverExportationFieldsAndFilters = async ({ effects: { users: effecs } }) => {
    const result = await effecs.usersApi.getExportationFieldsAndFilters();
    if (result && result.status >= 200 && result.status < 300) {
        return result.data;
    }
};
export const saveExportationFieldsAndFilters = async ({ effects: { users: effecs } }, config) => {
    await effecs.usersApi.saveExportationFieldsAndFilters(config);
};
export const deleteExportationFieldsAndFiltersSaved = async ({ effects: { users: effecs }, state: { users: state } }) => {
    // @ts-ignore
    const userId = state.currentUser ? state.currentUser.id : Vue.auth.user().id;
    await effecs.usersApi.deleteExportationFieldsAndFiltersSaved(userId);
};
export const getAccountId = async ({ state: { users: state }, actions }) => {
    if (state.planInformation._id.length == 0)
        await actions.users.getUserInformation();
    const account_id = state.planInformation._id;
    return account_id;
};
export const getUserSurveyGreaterThanSeven = async ({ state, effects: { users: effects }, }) => {
    if (state.users.surveyGreaterThanSeven === undefined) {
        const res = await effects.usersApi.getUserSurveyGreaterThanSeven();
        if (res && res.status === 200) {
            return (state.users.surveyGreaterThanSeven = res.data);
        }
    }
    return;
};
export const getUserAccountPreferences = async ({ effects: { users: effect }, }) => {
    const res = effect.usersApi.getUserAccountPreferences();
    return res;
};
export const setUserAccountPreferences = async ({ effects: { users: effect } }, data) => {
    const res = effect.usersApi.setUserAccountPreferences(data);
    return res;
};
export const getUserRefreshToken = async ({ effects: { users: effects } }, userToken) => {
    const new_token = await effects.usersApi.getUserRefreshToken(userToken);
    if (new_token && new_token.status && new_token.status == 200)
        return new_token;
    else
        return false;
};
export const addCreateListAutomaticEvent = async ({ effects: { users: effects }, }) => {
    const result = await effects.usersApi.addCreateListAutomaticEvent();
    if (result && result.status === 201)
        return result;
    else
        return false;
};
export const getCreateListAutomaticEvent = async ({ effects: { users: effects }, state, }) => {
    const result = await effects.usersApi.getCreateListAutomaticEvent();
    if (result && result.status === 200) {
        state.search.createAutomaticEventExist = !!result.data;
        return result;
    }
    else
        return false;
};
export const updateAccountCredits = async ({ state: { users: state } }, data) => {
    state.accountCredits = data;
    if (state.accountCredits &&
        state.accountCredits.clicks &&
        state.accountCredits.export) {
        const infos = {
            consult_consumption: state.accountCredits.clicks.percentage
                ? state.accountCredits.clicks.percentage
                : 0,
            export_consumption: state.accountCredits.export.percentage
                ? state.accountCredits.export.percentage
                : 0,
        };
        mixpanelUpdateUserCreditsConsumption(infos);
    }
};
export const getUserCreditsPercentage = async ({ actions }) => {
    const result = await actions.users.getUserCreditsInformation();
    if (result) {
        const userCredits = { ...result.credits } || {};
        const getClickPercentage = getCreditsResult(userCredits, 'click');
        const getExportPercentage = getCreditsResult(userCredits, 'export');
        const data = {
            clicks: getClickPercentage,
            export: getExportPercentage,
        };
        return data;
    }
};
export const tryCreateNewAccount = async ({ effects: { users: effects } }, data) => {
    const request = await effects.usersApi.tryCreateNewAccount(data);
    return request;
};
export const tryCreateAuthToken = async ({ effects: { users: effects } }, data) => {
    const request = await effects.usersApi.tryCreateAuthToken(data);
    return request;
};
export const tryAuthLogin = async ({ effects: { users: effects } }, data) => {
    const request = await effects.usersApi.tryAuthLogin(data);
    return request;
};
export const createAccountWithSocialMedia = async ({ effects: { users: effects } }, data) => {
    const request = await effects.usersApi.createAccountWithSocialMedia(data);
    return request;
};
export const getUserExists = async ({ effects: { users: effects }, state }, data) => {
    const request = await effects.usersApi.getUserExists(data);
    return request;
};
export const checkUserCurrentJorney = async ({ effects: { users: effects }, state, }) => {
    const request = await effects.usersApi.checkUserCurrentJorney();
    if (request && request.status == 200) {
        state.users.userJourney['user_journey'] = request.data.user_journey;
        state.users.userJourney['is_new_account'] = request.data.is_new_account;
        if (request.data.current_data) {
            state.users.userJourney['current_data'] = request.data.current_data;
        }
    }
};
export const updateOnboardingUserJorney = async ({ state, actions, effects: { users: effects } }, data) => {
    const request = await effects.usersApi.updateOnboardingUserJorney(data);
    if (request) {
        state.search.activeModal = '';
        state.search.updateDataPopUp.showModal = false;
        return request;
    }
};
export const sendWhatsappConfirmationUserJorney = async ({ effects: { users: effects } }, data) => {
    return await effects.usersApi.sendWhatsappConfirmationUserJorney(data);
};
export const getReferralList = async ({ effects: { users: effects } }) => {
    const getReferral = await effects.usersApi.getReferralList();
    return getReferral;
};
export const getReferralLink = async ({ effects: { users: effects } }) => {
    const getReferral = await effects.usersApi.getReferralLink();
    return getReferral;
};
export const getReferralOpenModal = async ({ effects: { users: effects } }) => {
    const getReferral = await effects.usersApi.getReferralOpenModal();
    return getReferral;
};
export const setReferralOpenModal = async ({ effects: { users: effects } }) => {
    const getReferral = await effects.usersApi.setReferralOpenModal();
    return getReferral;
};
export const getAuthPermissions = () => {
    //@ts-ignore
    const user = Vue.auth.user();
    if (user) {
        const speedioUser = user.email.includes('@speedio');
        const originBypass = ['closers', 'Conta Teste'];
        const campaignBypass = ['totvs'];
        const userOriginBypass = !user.origin || originBypass.includes(user.origin);
        const userCampaignBypass = campaignBypass.includes(user.campaign) || false;
        const userFree = !user.accountType ||
            user.accountType == 'free' ||
            user.accountType == 'undefined';
        return !(!speedioUser &&
            !userOriginBypass &&
            !userCampaignBypass &&
            userFree);
    }
    return 'auth-not-found';
};
export const canOpenExportModal = () => {
    //@ts-ignore
    const user = Vue.auth.user();
    if (!user)
        return false;
    const speedioUser = user.email.includes('@speedio');
    if (speedioUser)
        return true;
    const userOriginBypass = ['closers', 'Conta Teste', null].includes(user.origin) ||
        user.skipOriginCheck == true;
    const userCampaignBypass = ['totvs'].includes(user.campaign);
    const userFree = ['free', 'undefined', null].includes(user.accountType);
    return !userFree || userOriginBypass || userCampaignBypass;
};
export const authenticatedUserToShowWelcomeScreen = () => {
    //@ts-ignore
    const user = Vue.auth.user();
    if (user) {
        const speedioUser = user.email.includes('@speedio');
        return !speedioUser;
    }
    return true;
};
export const sendIncompatibleMobileEmail = async ({ effects: { users: effects }, }) => {
    const request = await effects.usersApi.sendIncompatibleMobileEmail();
    if (request.status == 200) {
        Notify.create({
            message: 'E-mail enviado com sucesso',
            type: 'success',
        });
    }
};
export const dontShowSpmPromoBannerAgain = async ({ effects, state }) => {
    await effects.users.usersApi.updateSpmBannerToUser();
    state.users.isSpmBannerAvailableToUser = false;
};
export const getSpmPromoBanner = async ({ effects, state }) => {
    const res = await effects.users.usersApi.getSpmBannerToUser();
    if (res) {
        if (state.users.isSpmBannerAvailableToUser === res)
            return;
        const data = res.data.value;
        state.users.isSpmBannerAvailableToUser = data;
    }
};
export const closeSpmPromoBannerByState = ({ state }) => {
    state.users.isSpmBannerAvailableToUser = false;
};
export const openSpmPromoBannerByState = ({ state }) => {
    state.users.isSpmBannerAvailableToUser = true;
};
export const suggestSpeedmail = async ({ effects, state }) => {
    const res = await effects.users.usersApi.suggestSpeedmail();
};
export const contactSupport = async ({ effects: { users: effects } }) => {
    const request = await effects.usersApi.contactSupport();
    return request;
};
export const getShowHyperbolic = async ({ effects }) => {
    const result = await effects.users.usersApi.getShowHyperbolic();
    return result;
};
export const experienceUserHyperbolic = async ({ effects, state }, data) => {
    const res = await effects.users.usersApi.experienceUserHyperbolic(data);
};
export const tryToRegisterNewAdditionalUser = async ({ effects, state }) => {
    const res = (await effects.users.usersApi.tryToRegisterNewAdditionalUser())
        ? effects.users.usersApi.tryToRegisterNewAdditionalUser()
        : '';
};
export const tryToImportWithoutCredits = async ({ effects, state }) => {
    const res = await effects.users.usersApi.tryToImportWithoutCredits();
};
export const enterSmConfig = async ({ effects, state }) => {
    const res = await effects.users.usersApi.enterSmConfig();
};
export const clickedOnUpgrade = async ({ effects, state }) => {
    const res = await effects.users.usersApi.clickedOnUpgrade();
};
export const scheduledDemo = async ({ effects, state }) => {
    const res = await effects.users.usersApi.scheduledDemo();
    if (!res.data.success)
        return;
    Notify.create({
        message: 'Um dos nossos consultores entrará em contato com você em breve',
        type: 'success',
        timeout: 30000,
    });
};
export const getDisplayWelcomeScreen = async ({ effects }) => {
    const res = await effects.users.usersApi.displayWelcomeScreen();
    return res.data.value;
};
export const welcomeScreenSearch = async ({ effects }, data) => {
    const res = await effects.users.usersApi.welcomeScreenSearch(data);
    return res;
};
export const toggleAutoplayActive = async ({ effects }, data) => {
    await effects.users.usersApi.toggleAutoplayActive(data);
};
export const disableAllAutoplay = async ({ effects }) => {
    await effects.users.usersApi.disableAllAutoplay();
};
export const saveAutoplay = async ({ effects }, data) => {
    await effects.users.usersApi.saveAutoplay(data);
};
export const getAutoplay = async ({ effects }, id) => {
    return await effects.users.usersApi.getAutoplay(id);
};
export const saveCrmAutoplay = async ({ effects }, data) => {
    const crm = data.crm;
    const token = data.token;
    await effects.users.usersApi.saveCrmAutoplay(crm, token);
};
export const getCrmAutoplay = async ({ effects }, crm) => {
    const result = await effects.users.usersApi.getCrmAutoplay(crm);
    return result;
};
export const getInfoAutoPlay = async ({ effects }) => {
    return await effects.users.usersApi.getInfoAutoPlay();
};
export const getAnswersWelcomeScreen = async ({ effects }) => {
    const result = await effects.users.usersApi.getAnswersWelcomeScreen();
    return result;
};
export const getDefaultSearch = async ({ effects, state }) => {
    const result = await effects.users.usersApi.getDefaultSearch();
    if (result && result.data && result.data.default_search) {
        state.search.defaultSearch = {
            id: result.data.default_search,
            active: result.data.using_default_search,
        };
        return result;
    }
    state.search.defaultSearch.active = false;
    state.search.defaultSearch.id = '';
    return result;
};
export const updateDefaultSearch = async ({ effects, state }, data) => {
    const result = await effects.users.usersApi.updateDefaultSearch(data);
    state.search.defaultSearch = result.data.data;
    return result.data;
};
export const setIsOnboardingSmallFormFields = ({ state }, value) => {
    state.users.isOnboardingSmallFormFields = value;
};
export const getShowAvaliableAutoplay = async ({ effects }) => {
    const result = await effects.users.usersApi.getShowAvaliableAutoplay();
    return result;
};
export const setWelcomeScreenAnswer = ({ state }, data) => {
    state.users.welcomeScreenAnswer[data.name] = data.value;
};
export const setAutoplay = ({ state: { search: state, users: usersState }, }) => {
    const accountIsActive = ['active', 'suspended', 'migrated'].includes(usersState.currentUser.accountType);
    const autoplayActiveCount = state.histories.filter(history => history.autoplay == true).length;
    if (accountIsActive && autoplayActiveCount > 0)
        state.showAutoPlay = true;
    state.showAutoPlay = true;
};
export const createNewNote = async ({ effects }, data) => {
    const result = await effects.users.usersApi.createNewNote(data);
    if (result) {
        return result;
    }
};
export const deletNote = async ({ effects }, id) => {
    //@ts-ignore
    const result = await effects.users.usersApi.deletNote(id);
    if (result) {
        return result;
    }
};
export const getAllNotes = async ({ effects }, companyId) => {
    //@ts-ignore
    const result = await effects.users.usersApi.getAllNotes(companyId);
    if (result) {
        return result;
    }
};
export const closedWelcomeBackModal = async ({ effects }) => {
    const result = await effects.users.usersApi.closedWelcomeBackModal();
    return result;
};
