import { json } from 'overmind';
import { SearchHistoryStatus } from '@/overmind/search/state';
import { Notify } from 'quasar';
import { accountIsActive, hasAutoplayActive } from '@/shared/utils/helpers';
export const getSearchHistory = async ({ state: { search: state, users: usersState }, effects, actions: { search: actions, users: actionsUsers }, }) => {
    const result = await effects.search.api.getSearchHistories();
    if (result) {
        const results = result.data.data;
        if (results.length == 0) {
            state.showAutoPlay = false;
            actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.NOT_FOUND);
            return;
        }
        const accountIsActive_ = accountIsActive(usersState.currentUser.accountType);
        const hasAutoplayActive_ = hasAutoplayActive(result.data.data);
        if (accountIsActive_ && hasAutoplayActive_)
            state.showAutoPlay = true;
        state.histories = results.map(item => {
            return {
                id: item.id,
                searchId: item.attributes.search_id.$oid,
                name: item.attributes.name,
                totalResults: item.attributes.total_results,
                createdAt: item.attributes.created_at,
                autoplay: item.attributes.autoplay,
                autoplayId: item.attributes.autoplay_id
                    ? item.attributes.autoplay_id.$oid
                    : '',
                selected_filters: item.attributes.selected_filters,
                totalDecisors: item.attributes.total_decisors,
                totalEmployees: item.attributes.total_decisors,
            };
        });
    }
    return;
};
export const createSearchHistory = async ({ effects, actions: { search: actions, users: actionsUsers }, state: { search: state, users: usersState }, }, search) => {
    const oldHistories = json(state.histories);
    const newHistories = [...oldHistories, search];
    if (oldHistories.some(o => o.name.replace(/\s+/g, '') === search.name.replace(/\s+/g, ''))) {
        Notify.create({
            message: 'Nome da lista já existe no histórico',
            type: 'error',
        });
    }
    else {
        actions.searchHistory.setSearchHistories(newHistories);
        const result = await effects.search.api.createSearchHistory(search);
        if (result.data || result.data.status === 201) {
            const accountIsActive_ = accountIsActive(usersState.currentUser.accountType);
            const hasAutoplayActive_ = hasAutoplayActive(result.data.data);
            if (accountIsActive_ && hasAutoplayActive_)
                state.showAutoPlay = true;
            actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.CREATED);
            const histories = json(state.histories);
            histories[histories.length - 1].id = result.data.data.id;
            actions.searchHistory.setSearchHistories(histories);
            setTimeout(() => {
                actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.IDLE);
            }, 2000);
        }
        else {
            actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.ERROR);
            actions.searchHistory.setSearchHistories(oldHistories);
        }
        return result;
    }
};
export const deleteSearchHistory = async ({ effects: { search: effects }, actions: { search: actions }, state: { search: state }, }, id) => {
    const filterId = json(state.histories);
    const oldHistories = state.histories;
    const histories = filterId.filter(history => {
        if (history.id !== id)
            return history;
    });
    if (id) {
        const newHistories = [...histories];
        actions.searchHistory.setSearchHistories(newHistories);
        const result = await effects.api.removeSearchHistory(id);
        if (result) {
            actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.DELETED);
        }
        else {
            actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.ERROR);
            actions.searchHistory.setSearchHistories(oldHistories);
        }
    }
    setTimeout(() => {
        actions.searchHistory.setSearchHistoryStatus(SearchHistoryStatus.IDLE);
    }, 2000);
};
export const setSearchHistories = ({ state: { search: state } }, histories) => {
    state.histories = json(histories);
};
export const setSearchHistoryStatus = ({ state: { search: state } }, value) => {
    state.searchHistoryStatus = value;
};
